import React from 'react';
import { Link } from 'react-router-dom';

function ProjectCard({ id, title, description, technologies, type }) {
  return (
    <div className="project-card">
      <h3>{title}</h3>
      <p>{description}</p>
      <p>Technologies: {technologies.join(', ')}</p>
      <p>Type: {type}</p>
      <Link to={`/projects/${id}`} className="read-more">View Details</Link>
    </div>
  );
}

export default ProjectCard;