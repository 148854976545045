import React from 'react';
import ProjectCard from '../components/ProjectCard';

const projects = [
    {
        id: 1,
        title: 'Static HTML Server from Structure Data',
        description: 'A Node.js web server that generates HTML pages from static JSON data for MLB standings.',
        technologies: ['Node.js', 'JavaScript', 'HTML', 'CSS'],
        type: 'Web Development',
      },
      {
        id: 2,
        title: 'Contact List Application',
        description: 'A full-stack web application for managing contacts with user authentication and authorization.',
        technologies: ['Node.js', 'Express', 'SQLite', 'Pug', 'bcrypt'],
        type: 'Full-Stack Development',
      },
      {
        id: 3,
        title: 'Responsive CSS Redesign',
        description: 'A responsive redesign of the Contact List Application using custom CSS and Bootstrap.',
        technologies: ['CSS', 'Bootstrap', 'Responsive Design'],
        type: 'Front-End Development',
      },
      {
        id: 4,
        title: 'Interactive Map Application',
        description: 'A single-page application with an interactive map for managing and displaying locations.',
        technologies: ['JavaScript', 'Node.js', 'Express', 'Leaflet.js', 'AJAX', 'Geocoding'],
        type: 'Full-Stack Development',
      }

  // Add more projects that showcase your diverse skills
];

function Projects() {
  return (
    <div className="projects">
      <h2>My Projects</h2>
      <div className="project-grid">
        {projects.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
    </div>
  );
}

export default Projects;