import React from 'react';

function SkillCard({ category, skills }) {
  return (
    <div className="skill-card">
      <h3>{category}</h3>
      <ul>
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </div>
  );
}

export default SkillCard;