import React from 'react';
import SkillCard from '../components/SkillCard';

const skillSets = [
  {
    category: 'Software Development',
    skills: [
      'JavaScript',
      'React',
      'Node.js',
      'Express',
      'React Native',
      'HTML/CSS',
      'API Development',
      'C++',
      'C#',
    ],
  },
  {
    category: 'Database Development',
    skills: [
      'SQL',
      'MongoDB',
      'PostgreSQL',
      'Firebase',
    ],
  },
];

function Skills() {
  return (
    <div className="skills">
      <h2>My Skills</h2>
      <div className="skills-grid">
        {skillSets.map((skillSet, index) => (
          <SkillCard key={index} {...skillSet} />
        ))}
      </div>
    </div>
  );
}

export default Skills;
