import React from 'react';
import { useParams } from 'react-router-dom';

const projects = {
        1: {
          title: 'Static HTML Server from Structure Data',
          description: 'A Node.js web server that generates HTML pages from static JSON data for MLB standings.',
          challenge: 'Create a web server without using external libraries or templating engines, generating all HTML dynamically from JSON data.',
          solution: 'Implemented a Node.js server using only built-in modules. Developed functions to read and filter JSON data, and generate HTML tables and lists dynamically based on URL parameters.',
          impact: 'Demonstrated understanding of basic web server implementation and dynamic content generation without relying on advanced frameworks or libraries.',
          technologies: ['Node.js', 'JavaScript', 'HTML', 'CSS', 'JSON'],
          type: 'Web Development',
          features: [
            'Dynamic generation of MLB standings pages for 2021 and 2022 seasons',
            'Filtering of standings by league and division',
            'Team information page with logos and details',
            'Responsive design using water.css',
            'Flexible architecture to handle different sports and seasons'
          ],
          githubLink: 'https://git.sr.ht/~dulerio/mlb-standings-generator/tree', // Add your GitHub link here
          liveLink: '' // Demo link
        },
        2: {
            title: 'Contact List Application',
            description: 'A full-stack web application for managing contacts with user authentication and authorization.',
            challenge: 'Develop a secure web application with user authentication, database integration, and dynamic content generation using modern web technologies.',
            solution: 'Implemented a Node.js and Express server with SQLite database, Pug templating, and bcrypt for password hashing. Created a complete CRUD system for contacts and user management.',
            impact: 'Demonstrated proficiency in full-stack development, including database design, server-side rendering, and security best practices.',
            technologies: ['Node.js', 'Express', 'SQLite', 'Pug', 'bcrypt', 'JavaScript', 'HTML', 'CSS'],
            type: 'Full-Stack Development',
            features: [
              'User registration and authentication system',
              'Contact creation, reading, updating, and deletion (CRUD)',
              'Dynamic HTML generation using Pug templating',
              'Secure password storage using bcrypt',
              'SQLite database integration',
              'RESTful routing',
              'Middleware for database connection and user session management'
            ],
            githubLink: 'https://git.sr.ht/~dulerio/basic-contact-manager/tree', // Add your GitHub link here
            liveLink: '' // Demo link
          },
          3: {
            title: 'Responsive CSS Redesign',
            description: 'A responsive redesign of the Contact List Application using custom CSS and Bootstrap.',
            challenge: 'Enhance the user interface and experience of the Contact List Application by implementing responsive design principles and professional styling.',
            solution: 'Applied custom CSS and Bootstrap to create a consistent, professional look across all pages. Implemented responsive design techniques to ensure optimal viewing on both desktop and mobile devices.',
            impact: 'Significantly improved the application\'s usability and visual appeal, demonstrating proficiency in modern front-end development practices.',
            technologies: ['CSS', 'Bootstrap', 'Responsive Design', 'Flexbox', 'Media Queries'],
            type: 'Front-End Development',
            features: [
              'Consistent color scheme and typography across all pages',
              'Responsive layout adapting to various screen sizes',
              'Improved table design for better data presentation',
              'Enhanced form styling for better user interaction',
              'Use of Flexbox for flexible and responsive layouts',
              'Implementation of Bootstrap components (if used)',
              'Custom CSS for unique design elements'
            ],
            githubLink: 'https://git.sr.ht/~dulerio/responsive-contacts-ui/tree', // Add your GitHub link here
            liveLink: '' // Add your live demo link here if available
          },
          4: {
            title: 'Interactive Map Application',
            description: 'A single-page application with an interactive map for managing and displaying locations.',
            challenge: 'Develop a full-stack application that integrates geocoding, interactive maps, and dynamic client-side updates.',
            solution: 'Implemented a Node.js backend with Express for API endpoints, integrated Leaflet.js for map rendering, and used AJAX for seamless client-side updates. Incorporated geocoding to convert addresses to coordinates.',
            impact: 'Created a dynamic and interactive web application that demonstrates proficiency in both front-end and back-end technologies, as well as third-party API integration.',
            technologies: ['JavaScript', 'Node.js', 'Express', 'Leaflet.js', 'AJAX', 'Geocoding', 'SQLite', 'HTML', 'CSS'],
            type: 'Full-Stack Development',
            features: [
              'Interactive map display using Leaflet.js',
              'Geocoding of addresses to latitude and longitude coordinates',
              'Dynamic addition and removal of map markers',
              'Client-side rendering of location data',
              'AJAX-powered create, read, update, and delete operations',
              'Clickable table rows to center map on location',
              'Responsive design for various screen sizes'
            ],
            githubLink: 'https://git.sr.ht/~dulerio/interactive-location-mapper/tree', // Add your GitHub link here
            liveLink: '' // Add your live demo link here if available
          },
};

function ProjectDetail() {
  const { id } = useParams();
  const project = projects[id];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="project-detail">
      <h2>{project.title}</h2>
      <p><strong>Type:</strong> {project.type}</p>
      <div className="project-links">
        {project.liveLink && project.liveLink !== '#' && (
          <a href={project.liveLink} target="_blank" rel="noopener noreferrer" className="project-link live-link">
            Live Demo
          </a>
        )}
        {project.githubLink && project.githubLink !== '#' && (
          <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="project-link view-code-link">
            View Code
          </a>
        )}
      </div>
      <h3>Challenge</h3>
      <p>{project.challenge}</p>
      <h3>Solution</h3>
      <p>{project.solution}</p>
      <h3>Impact</h3>
      <p>{project.impact}</p>
      <h3>Technologies Used</h3>
      <ul>
        {project.technologies.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectDetail;